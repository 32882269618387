<template>
  <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M9.7501 5.41667L7.58343 3.25M1.35425 11.6458L3.18745 11.4421C3.41142 11.4173 3.52341 11.4048 3.62808 11.3709C3.72095 11.3409 3.80932 11.2984 3.89081 11.2447C3.98266 11.1841 4.06234 11.1044 4.22168 10.9451L11.3751 3.79167C11.9734 3.19336 11.9734 2.22331 11.3751 1.625C10.7768 1.02669 9.80674 1.02669 9.20843 1.625L2.05502 8.7784C1.89567 8.93775 1.81599 9.01742 1.75543 9.10927C1.70169 9.19076 1.65921 9.27914 1.62915 9.372C1.59527 9.47668 1.58282 9.58866 1.55794 9.81264L1.35425 11.6458Z"
        stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>
<script>
export default {
  name: "IconPencil"
}
</script>
